<template>
  <v-card class="elevation-0 p-5">
    <div v-if="question">
      <!-- question -->
      <div class="d-flex flex-column mb-5 align-items-start">
        <AvatarWithDetails
          :user="question.owner"
          :detail="question.created_on"
          :isDate="true"
          :alignRight="false"
          :isAnon="anon"
        ></AvatarWithDetails>
        <div
          class="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left min-w-300px"
        >
          {{ question.question }}
        </div>
      </div>
      <!-- answer -->
      <div
        v-if="question.status == 'answered'"
        class="d-flex flex-column mb-5 align-items-end"
      >
        <AvatarWithDetails
          :user="question.doctor.doctor_id"
          :detail="question.created_on"
          :isDate="true"
          :alignRight="true"
        ></AvatarWithDetails>
        <div
          class="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-left min-w-300px"
        >
          {{ question.answer }}
        </div>
      </div>
      <!-- rating -->
      <div
        v-if="question.rating"
        class="d-flex flex-column mb-5 align-items-start"
      >
        <AvatarWithDetails
          :user="question.owner"
          :detail="question.created_on"
          :isDate="true"
          :alignRight="false"
          :isAnon="anon"
        ></AvatarWithDetails>
        <div
          class="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left"
        >
          <v-rating :value="question.rating" readonly></v-rating>
        </div>
      </div>
    </div>
    <div v-else>
      <h5>👈 {{ $t("QUES.questionTip") }}</h5>
    </div>
  </v-card>
</template>

<script>
//import ApiService from "@/core/services/api.service";
import AvatarWithDetails from "@/view/pages/utilComponents/avatar1.vue";

export default {
  name: "QuestionsDetails",
  props: ["question"],
  components: { AvatarWithDetails },
  data() {
    return {anon:false};
  },
  watch: {
    question: function(newQ) {
      //console.log("checking owner ship / current usr",JSON.parse(localStorage.userInfo).id, " q owner ", newQ.owner.id)
      if(JSON.parse(localStorage.userInfo).id != newQ.owner.id)
      {
        this.anon = true;
      }
      else
      {
        this.anon = false;
      }
    }
  },
  beforeMount()
  {
    
  },
  mounted() {},
  methods: {}
};
</script>
