import { render, staticRenderFns } from "./quDetails.vue?vue&type=template&id=528916c0&"
import script from "./quDetails.vue?vue&type=script&lang=js&"
export * from "./quDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VCard,VRating})
