<template>
  <v-card v-if="!this.$root.mobile" class="elevation-0">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('APPOINTMENTS.APP.search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :items="questions"
      :search="search"
      :loading="loadingStatus"
      :locale="this.$root.lang"
      :headers="headers"
      :loading-text="$t('tLoading')"
      @click:row="rowclicked"
      hide-default-header
    >
      <template v-slot:item.owner.first_name="{ item }">
        <AvatarWithDetails
          :user="item.owner"
          :detail="questionsum(item.question)"
          :isDate="false"
          :alignRight="false"
        ></AvatarWithDetails>
      </template>

      <template v-slot:item.title="{ item }"> </template>
      <template v-slot:item.created_on="{ item }">
        <div
          class="d-flex align-items-center "
          v-b-tooltip.hover.left="setToolTip(item.created_on)"
        >
          <span
            class="text-muted font-weight-bold font-size-sm text-right"
            style="width:100%"
            >{{ item.created_on | moment("from", "now") }}</span
          >
        </div>
      </template>
    </v-data-table>
  </v-card>

  <div v-else>
    <div
      class="text-center"
      style="width: 100%; position: absolute; top:25rem"
      v-if="loadingStatus"
    >
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div>
    <v-list v-else one-line class="bg-transparent px-3">
      <template>
        <div v-for="item in questions" :key="item.id" class="mb-2 listitem">
          <v-list-item style="min-height:80px" @click="listItemClicked(item)">
            <v-list-item-avatar size="47" color="#F2F7FD">
              <v-img
                v-if="item.department.avatar != null" height="25" contain
                :src="$root.assetsBaseURL + ''+ item.department.avatar.data.asset_url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ item.department.name.charAt(0) }}
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="listitemtitle" >{{ item.title }}
                  
                </div>
                <div class="listitemsubtitle">{{ questionsum(item.question) }}</div>
                <div class="listitemsubtitle">
                {{
                  (item.created_on)
                    | moment(
                      "timezone",
                      getTimeZone(),
                      "ddd DD/MM/YYYY hh:mm A"
                    )
                }}
                {{ (item.created_on)
                | moment("timezone", getTimeZone(), "from", "now") }}</div>
            </v-list-item-content>
            
          </v-list-item>
        </div>
      </template>
    </v-list>
    <div
      class="text-center mt-10"
      style=""
      v-if="questions.length == 0 && !loadingStatus"
    >
      <span class="text-muted font-size-h4">{{
        $t("QUES.noquestionsfound")
      }}</span>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import AvatarWithDetails from "@/view/pages/utilComponents/avatar1.vue";

export default {
  name: "QuestionsUnaswered",
  props: ["depart"],
  components: { AvatarWithDetails },
  data() {
    return {
      search: "",
      error: "",
      loadingStatus: true,
      questions: [],
      headers: [
        {
          text: this.$t("QUES.header.user"),
          align: "left",
          sortable: true,
          value: "owner.first_name"
        },
        { text: this.$t("QUES.header.title"), value: "title" },
        {
          text: this.$t("QUES.header.date"),
          value: "created_on",
          align: "right"
        }
      ],
      lastSelected: null
    };
  },
  mounted() {
    if (this.depart == "answered") {
      this.getAnsweredQuestions();
    } else if (this.depart == "unaswered") {
      this.getUnAnsweredQuestions();
    } else {
      this.getQuestions();
    }
    ////console.log("in comp depart is ", this.depart)
  },
  watch: {
    depart: function() {
      console.log("new depart: ", this.depart);
      this.loadingStatus = true;
      if (this.depart == "answered") {
        this.getAnsweredQuestions();
      } else if (this.depart == "unanswered") {
        this.getUnAnsweredQuestions();
      } else {
        this.getQuestions();
      }
    }
  },
  methods: {
    getTimeZone() {
      return localStorage.timezone;
    },
    async getQuestions() {
      await ApiService.get(
        "items",
        "question?fields=id,created_on,status,owner.*.*,title,question,answer,rating,department.*.*,doctor.doctor_id.*.*" +
          "&sort=-created_on&filter[department]=" +
          this.depart
      )
        .then(({ data }) => {
          ////console.log(data.data);
          this.questions = data.data;
          this.loadingStatus = false;
        })
        .catch(error => {
          this.error = error;
        });
    },
    async getAnsweredQuestions() {
      await ApiService.get(
        "items",
        "question?fields=id,created_on,status,owner.*.*,title,question,answer,rating,department.*.*,doctor.doctor_id.*.*" +
          "&filter[status]=answered&sort=-created_on&filter[owner]=" +
          JSON.parse(localStorage.userInfo).id
      )
        .then(({ data }) => {
          ////console.log(data.data);
          this.questions = data.data;
          this.loadingStatus = false;
        })
        .catch(error => {
          this.error = error;
        });
    },
    async getUnAnsweredQuestions() {
      await ApiService.get(
        "items",
        "question?fields=id,created_on,status,owner.*.*,title,question,answer,rating,department.*.*,doctor.doctor_id.*.*" +
          "&filter[status]=notanswered&sort=-created_on&filter[owner]=" +
          JSON.parse(localStorage.userInfo).id
      )
        .then(({ data }) => {
          console.log(data.data);
          this.questions = data.data;
          this.loadingStatus = false;
        })
        .catch(error => {
          this.error = error;
        });
    },

    setToolTip(date) {
      console.log(this.$moment.locale());

      return this.$moment(date).format("dddd, MMMM Do YYYY");
    },
    rowclicked(value, row) {
      if (this.lastSelected) {
        this.lastSelected.select(false);
      }
      row.select(true);
      this.lastSelected = row;
      this.$emit("questionClicked", value);
    },
    listItemClicked(value) {
      console.log("question clicked is", value);
      this.$emit("listQuestionClicked", value);
    },
    questionsum(q) {
      if (q.length < 50) {
        return q;
      } else {
        return q.substring(0, 50) + " ...";
      }
    },
    clearSelection() {
      if (this.lastSelected) {
        this.lastSelected.select(false);
        this.lastSelected = null;
      }
    }
  }
};
</script>
<style scoped>

</style>