<template>
  <div>
    <!-- start web toolbar -->
    <v-toolbar
      flat
      rounded
      style="
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      "
      v-if="!this.$root.mobile"
    >
      <v-btn-toggle color="primary accent-3" tile group mandatory>
        <v-btn @click="switchTab(0)">
          <v-icon>mdi-account</v-icon>
          {{ $t('QUES.myQ') }}
        </v-btn>
        <v-btn @click="switchTab(1)">
          <v-icon>mdi-account-group</v-icon>
          {{ $t('QUES.allQ') }}
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="showadd = true" elevation="0" large>
        <v-icon left dark large class="mr-3"> mdi-plus-box </v-icon
        >{{ $t('MF.add') }}
      </v-btn>
    </v-toolbar>
    <!-- end web toolbar -->

    <!-- start mobile toolbar -->
    <div class="customnav" v-else>
        <div class="row customtab mx-0" >
          <div :class="'col-6 tabbtn tab_first_item '+ active_tab[0]">
            <div @click="switchTab(0)"
              >{{ $t('QUES.myQ') }}</div
            >
          </div>
          <div :class="'col-6 tabbtn tab_last_item '+ active_tab[1]">
            <div @click="switchTab(1)"
              >{{ $t('QUES.allQ') }}</div
            >
          </div>
        </div>
      
    </div>
    

    <!-- for web -->
    <v-toolbar
      flat
      rounded
      class="pl-3 mb-3 bg-gray-100"
      style="
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      "
      v-if="!this.$root.mobile"
    >
      <v-btn-toggle
        v-if="showmine"
        color="primary accent-3"
        tile
        group
        mandatory
        rounded
      >
        <v-btn @click="questionCateogry = 'answered'">
          {{ $t('QUES.myAQ') }}
        </v-btn>
        <v-btn @click="questionCateogry = 'unanswered'">
          {{ $t('QUES.myUQ') }}
        </v-btn>
      </v-btn-toggle>
      <v-btn-toggle
        v-model="selectedDepart"
        v-if="showall"
        color="primary accent-3"
        tile
        group
        mandatory
        rounded
      >
        <v-btn
          v-for="(depart, i) in departments"
          :key="i"
          @click="questionCateogry = depart.id"
        >
          {{ depart.name }}
        </v-btn>
      </v-btn-toggle>
    </v-toolbar>

    <!-- start mobile view -->
    <div class="row">
      <div style="width: 100%" v-if="this.$root.mobile">
        <v-bottom-sheet v-model="sheet" persistent>
          
          <v-card
            class="rounded-lg"
            style="
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            "
            :style="
          'padding-bottom: ' + this.$root.tabspadding + 'px;'"
          >
            <v-toolbar flat dark color="white" class="text-primary">
              <v-toolbar-title>{{ $t('QUES.catSelect') }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon dark color="primary" @click="sheet = !sheet">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <div class="p-3">
              <v-list v-if="showmine">
                <v-list-item-group color="primary" v-model="selectedMine">
                  <v-list-item
                    @click="
                      questionCateogry = 'answered';
                      selectedMine = 0;
                      sheet = !sheet;
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('QUES.myAQ') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="
                      questionCateogry = 'unanswered';
                      selectedMine = 1;
                      sheet = !sheet;
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('QUES.myUQ') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list v-else>
                <v-list-item-group v-model="selectedDepart" color="primary">
                  <v-list-item
                    v-for="(depart, i) in departments"
                    :key="i"
                    @click="
                      questionCateogry = depart.id;
                      sheet = !sheet;
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ getTranslation(depart).name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-card>
        </v-bottom-sheet>
      </div>
      <div v-if="!this.$root.mobile" :class="tabcontentcol">
        
        <Questions
          :depart="questionCateogry"
          @questionClicked="setQuestion($event)"
        ></Questions>
      </div>
      <div style="width:100%;" v-else>
        <v-list-item>
          <v-list-item-content class="text-primary px-1">
            
            <div style="font-size:1.2rem;" v-if="questionCateogry == 'answered'" >{{ $t('QUES.myAQ') }}</div>
            <div style="font-size:1.2rem;" v-else-if="questionCateogry == 'unanswered'">{{ $t('QUES.myUQ') }}</div>
            <div style="font-size:1.2rem;" v-else>{{ getTranslation(departments[selectedDepart]).name }}</div>
          </v-list-item-content>
          <v-list-item-action>
            <div @click="sheet = !sheet"><v-icon color="primary">mdi-filter-variant</v-icon></div>
          </v-list-item-action>
        </v-list-item>
        <Questions
          :depart="questionCateogry"
          @listQuestionClicked="showQuestionBottom($event)"
        ></Questions>
        <v-bottom-sheet v-model="qsheet">
          <v-card
            class="rounded-lg"
            style="
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            "
            :style="
          'padding-bottom: ' + this.$root.tabspadding + 'px;'"
          >
            <v-toolbar flat dark color="white" class="text-primary">
              <v-spacer />
              <v-btn icon dark color="primary" @click="qsheet = !qsheet">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <div class="p-3">
              <QuestionsDetails :question="selectedQuestion"></QuestionsDetails>
            </div>
          </v-card>
        </v-bottom-sheet>
      </div>
      <div v-if="!hideQuestionDetails" :class="questionDetailCol">
        <QuestionsDetails :question="selectedQuestion"></QuestionsDetails>
      </div>
    </div>

    <v-fab-transition v-if="this.$root.mobile">
      <v-btn
        color="primary"
        fab
        dark
        small
        fixed
        bottom
        right
        large
        :class="($root.tabspadding > 0) ? 'mb-25' :'mb-12'"
        @click="showadd = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <Add
      v-if="departments != null"
      :dialog="showadd"
      :departments="departments"
      @closeClicked="showadd = false"
    ></Add>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";
import Questions from './comp/questions/quAll.vue';
import QuestionsDetails from './comp/questions/quDetails.vue';
import Add from './comp/questions/quadd.vue';

export default {
  name: 'questions',
  components: { Questions, QuestionsDetails, Add },
  data() {
    return {
      error: '',
      loadingStatus: true,
      selectedQuestion: null,
      tabcontentcol: 'col-12',
      questionCateogry: 'answered',
      selectedDepart: 0,
      questionDetailCol: 'col-4  rounded',
      hideQuestionDetails: true,
      showmine: true,
      showall: false,
      departments: null,
      sheet: false,
      selectedMine: 0,
      qsheet: false,
      showadd: false,
      active_tab:["active_tab", ""]
    };
  },
  mounted() {
    this.getDepartments();
    this.$root.title = this.$t('MENU.questions');
    this.$root.currentpage = this.$route.name;
  },
  methods: {
    async getDepartments() {
      await ApiService.get('items', 'department?fields=id,name,avatar.data,description,translation.*')
        .then(({ data }) => {
          this.departments = data.data;
          this.loadingStatus = false;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    getTranslation(d) {
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
    tabChanged() {
      /*if (event == 0) {
        this.showAnswerFeild = true;
        this.$refs.quAll.clearSelection();
      } else if (event == 1) {
        this.showAnswerFeild = false;
        this.$refs.quAns.clearSelection();
      } else if (event == 2) {
        this.showAnswerFeild = false;
        this.$refs.quAnsMe.clearSelection();
      }*/
      this.selectedQuestion = null;
    },
    setQuestion(e) {
      this.tabcontentcol = 'col-8';
      this.hideQuestionDetails = false;
      console.log('selected question is: ', e);
      this.selectedQuestion = e;
    },
    showQuestionBottom(e) {
      console.log('in show sheet and q is', e);
      this.selectedQuestion = e;
      this.qsheet = true;
    },
    switchTab(clicked) {
      if (clicked == 0) {
        this.showmine = true;
        this.showall = false;
        this.selectedMine = 0;
        this.questionCateogry = 'answered';
        this.active_tab[0]= "active_tab";
        this.active_tab[1]= "";
      } else if (clicked == 1) {
        this.showmine = false;
        this.showall = true;
        this.active_tab[0]= "";
        this.active_tab[1]= "active_tab";
        this.questionCateogry = this.departments[this.selectedDepart]['id'];
      }
    },
    checkMargin() {
      if (this.$root.mobile) {
        return 'margin-top:50px !important';
      }
      return '';
    },
    displayQCat() {
      if (this.questionCateogry == 'answered') {
        return this.$t('QUES.myAQ');
      } else if (this.questionCateogry == 'unanswered') {
        return this.$t('QUES.myUQ');
      } else {
        console.log('departments', this.departments[this.selectedDepart]);
        console.log('selected department: ', this.selectedDepart);
        if (this.selectedDepart) {
          return this.departments[this.selectedDepart]['name'];
        } else {
          return this.departments[0]['name'];
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-application--wrap {
  min-height: 0px !important;
}

.v-data-table tr td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1rem !important;
  text-transform: uppercase;
}
</style>

<style>
table tbody tr td {
  border: 0px !important;
  cursor: pointer;
}
.question-menu {
  flex-direction: column;
}


</style>
